
import ActionTypes from '../actions/actionTypes';

const INITIAL_STATE = {
    balanceList: [],
    txList: [],
    error: {},
}

export default (state = { INITIAL_STATE }, action = {}) => {
    switch (action.type) {
        case (ActionTypes.GetBalanceList):
            return ({
                ...state,
                balanceList: action.payload,
            });
        case ActionTypes.GetOrderListError:
            return ({
                ...state,
                error: action.payload
            });
        case (ActionTypes.GetTxList):
            return ({
                ...state,
                txList: action.payload,
            });
        case ActionTypes.GetTxListError:
            return ({
                ...state,
                error: action.payload
            });
        default:
            return state;
    }
}