export const chainList = [
  {
    blockExplorerUrls: ['https://etherscan.io'],
    chainId: '0x1',
    chainName: 'Ethereum',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    rpcUrls: [
      'https://mainnet.infura.io/v3/e67a2556dede4ff2b521a375a1905f8b',
      'https://api.mycryptoapi.com/eth',
      'https://cloudflare-eth.com',
    ],
    blockScanUrl: 'https://etherscan.io',
  },
  {
    blockExplorerUrls: ['https://polygonscan.com'],
    chainId: '0x89',
    chainName: 'Polygon',
    nativeCurrency: { name: 'Matic', symbol: 'MATIC', decimals: 18 },
    rpcUrls: [
      'https://polygon-mainnet.infura.io/v3/e67a2556dede4ff2b521a375a1905f8b',
      'https://rpc-mainnet.matic.quiknode.pro',
      'wss://ws-mainnet.matic.network',
      'https://rpc-mainnet.matic.network',
    ],
    blockScanUrl: 'https://polygonscan.com',
  },
  {
    blockExplorerUrls: ['https://bscscan.com'],
    chainId: '0x38',
    chainName: 'Binance',
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: [
      'https://bsc-dataseed1.binance.org',
      'https://bsc-dataseed2.binance.org',
      'https://bsc-dataseed3.binance.org',
      'https://bsc-dataseed4.binance.org',
      'https://bsc-dataseed1.defibit.io',
      'https://bsc-dataseed2.defibit.io',
      'https://bsc-dataseed3.defibit.io',
      'https://bsc-dataseed4.defibit.io',
      'https://bsc-dataseed1.ninicoin.io',
      'https://bsc-dataseed2.ninicoin.io',
      'https://bsc-dataseed3.ninicoin.io',
      'https://bsc-dataseed4.ninicoin.io',
      'wss://bsc-ws-node.nariox.org',
    ],
    blockScanUrl: 'https://bscscan.com',
  },
  ...(process.env.NODE_ENV === 'development'
    ? [
        {
          blockExplorerUrls: ['https://testnet.bscscan.com'],
          chainId: '0x61',
          chainName: 'Smart Chain - Testnet',
          nativeCurrency: {
            name: 'Test Binance Coin',
            symbol: 'BNB',
            decimals: 18,
          },
          rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
          blockScanUrl: 'https://testnet.bscscan.com',
        },
        {
          blockExplorerUrls: ['https://sepolia.etherscan.io'],
          chainId: '0xAA36A7',
          chainName: 'Sepolia test network',
          nativeCurrency: {
            name: 'Sepolia ETH',
            symbol: 'SepoliaETH',
            decimals: 18,
          },
          rpcUrls: ['https://sepolia.etherscan.io'],
        },
        {
          blockExplorerUrls: [],
          chainId: '0x539',
          chainName: 'Local Network',
          nativeCurrency: { name: 'Ethereum', symbol: 'GETH', decimals: 18 },
          rpcUrls: ['http://192.198.123.101:7545'],
        },
      ]
    : []),
];

export const STANDARD_DECIMAL = 18;

export const AllowedAccessIPs = ['188.43.14.13', '201.229.48.165', '186.189.173.1'];
