import { combineReducers } from 'redux';

import authReducer from './auth';
import tokenReducer from './token';
import stakeReducer from './stake';
import orderReducer from './order';
import tradeReducer from './trade';
import balanceReducer from './balance';
import newsReducer from './news';
import usersReducer from './users';
import customerStoryReducer from './customerStory';

export default combineReducers({
    auth: authReducer,
    token: tokenReducer,
    stake: stakeReducer,
    order: orderReducer,
    trade: tradeReducer,
    balance: balanceReducer,
    news: newsReducer,
    users: usersReducer,
    customerStory: customerStoryReducer
})