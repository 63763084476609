
import ActionTypes from '../actions/actionTypes' ;

const INITIAL_STATE = {
    message: "IDLE",
    error: {},
    usersData : [],    
}

export default ( state={INITIAL_STATE} , action={} ) => {
    switch(action.type) {
        case (ActionTypes.GetUserList):
            return ({
                ...state,
                usersData : action.payload,
            });
        case (ActionTypes.AddUser):
            return ({
                ...state,
                usersData : action.payload.data,
                message : "SUCCESS"
            });
        case ActionTypes.AddUserError:
            return ({
                ...state,
                error: action.payload,
                message : "ERROR"
            });
        default :
            return state ;
    }
}